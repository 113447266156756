var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project_form"},[_c('div',{staticClass:"project_form__wrapper"},[_c('div',{staticClass:"top"},[_c('h2',[_vm._v("Актуализация данных")]),_c('div',{staticClass:"status",style:(`background-color: ${_vm.project.status.hex_color}`)},[_vm._v(" Статус: "+_vm._s(_vm.project.status.name)+" ")])]),(_vm.actionInfo || _vm.viewMode)?_c('a-form-model',{ref:"investForm",attrs:{"model":_vm.form}},[_c('div',{staticClass:"form_block"},[_c('div',{staticClass:"form_block__header"},[_c('h3',[_vm._v("Основная информация о проекте")])]),_c('div',{staticClass:"grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2"},[(_vm.actionInfo?.stage || _vm.viewMode)?_c('a-form-model-item',{ref:"stage",attrs:{"label":"Стадия проекта","rules":{
                            required: false,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"stage"}},[_c('a-select',{staticClass:"w-full",attrs:{"placeholder":"Стадия проекта","size":"large","disabled":_vm.viewMode,"default-active-first-option":false,"not-found-content":null,"loading":_vm.stageLoading},model:{value:(_vm.form.stage),callback:function ($$v) {_vm.$set(_vm.form, "stage", $$v)},expression:"form.stage"}},_vm._l((_vm.stageList.results),function(stage){return _c('a-select-option',{key:stage.id,attrs:{"value":stage.id}},[_vm._v(" "+_vm._s(stage?.string_view)+" ")])}),1)],1):_vm._e(),(_vm.actionInfo?.installation_stage || _vm.viewMode)?_c('a-form-model-item',{ref:"installation_stage",attrs:{"label":"Стадия СМР, %","rules":{
                            required: true,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"installation_stage"}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"min":0,"max":100,"placeholder":"Стадия СМР, %","size":"large"},model:{value:(_vm.form.installation_stage),callback:function ($$v) {_vm.$set(_vm.form, "installation_stage", $$v)},expression:"form.installation_stage"}})],1):_vm._e()],1),_c('div',{staticClass:"grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2"},[(_vm.actionInfo?.project_capacity || _vm.viewMode)?_c('a-form-model-item',{ref:"project_capacity",attrs:{"label":"Мощность проекта","rules":{
                            required: true,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"project_capacity"}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"step":0.01,"placeholder":"Укажите мощность проекта","size":"large"},model:{value:(_vm.form.project_capacity),callback:function ($$v) {_vm.$set(_vm.form, "project_capacity", $$v)},expression:"form.project_capacity"}})],1):_vm._e(),(_vm.actionInfo?.measure_unit || _vm.viewMode)?_c('a-form-model-item',{ref:"measure_unit",attrs:{"label":"Единица измерения","rules":{
                            required: true,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"measure_unit"}},[_c('a-select',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"placeholder":"Единица измерения","size":"large","default-active-first-option":false,"not-found-content":null,"loading":_vm.measureUnitLoading},model:{value:(_vm.form.measure_unit),callback:function ($$v) {_vm.$set(_vm.form, "measure_unit", $$v)},expression:"form.measure_unit"}},_vm._l((_vm.measureUnitList.results),function(unit){return _c('a-select-option',{key:unit.id,attrs:{"value":unit.code}},[_vm._v(" "+_vm._s(unit.string_view)+" ")])}),1)],1):_vm._e()],1)]),_c('div',{staticClass:"form_block"},[_c('div',{staticClass:"form_block__header"},[_c('h3',[_vm._v("Стоимость проекта")])]),_c('div',{staticClass:"grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2"},[(_vm.actionInfo?.funds || _vm.viewMode)?_c('a-form-model-item',{ref:"funds",attrs:{"label":"Вся стоимость проекта, млн. тенге","rules":{
                            required: true,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"funds"}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"step":0.01,"placeholder":"Вся стоимость проекта","size":"large"},model:{value:(_vm.form.funds),callback:function ($$v) {_vm.$set(_vm.form, "funds", $$v)},expression:"form.funds"}})],1):_vm._e()],1),_c('div',{staticClass:"grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2"}),(_vm.actionInfo?.funding_sources || _vm.viewMode)?_c('div',{staticClass:"form_block"},[_vm._l((_vm.form.funding_sources),function(source,index){return _c('div',{key:source.key,class:_vm.form.funding_sources.length > 1 && 'mb-3'},[_c('div',{staticClass:"equipment_header flex items-center justify-between"},[_c('div',{staticClass:"e_label"},[_vm._v("Источник финансирования")]),(_vm.form.funding_sources.length > 1)?_c('div',[_c('a-button',{attrs:{"disabled":_vm.viewMode,"type":"link","size":"small"},on:{"click":function($event){return _vm.removeSource(index)}}},[_vm._v(" Удалить ")])],1):_vm._e()]),_c('div',{staticClass:"grid gap-4 md:gap-0 xl:gap-6 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2"},[_c('div',[_c('a-form-model-item',{attrs:{"label":"Источник","prop":'funding_sources.' + index + '.funding_source',"rules":{
                                        required: true,
                                        message: _vm.$t('wgr.field_require'),
                                        trigger: 'blur',
                                    }}},[_c('a-select',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"placeholder":"Укажите источник финансирования","size":"large","default-active-first-option":false,"not-found-content":null,"show-search":"","filter-option":_vm.filterOption,"loading":_vm.sourceLoading},model:{value:(source.funding_source),callback:function ($$v) {_vm.$set(source, "funding_source", $$v)},expression:"source.funding_source"}},_vm._l((_vm.fundingSourceList.results),function(iSource){return _c('a-select-option',{key:iSource.id,attrs:{"value":iSource.id}},[_vm._v(" "+_vm._s(iSource.string_view)+" ")])}),1)],1),_c('a-form-model-item',{attrs:{"prop":'funding_sources.' + index + '.amount',"label":"Объем финансирования, млн. тенге","rules":{
                                        required: true,
                                        message: _vm.$t('wgr.field_require'),
                                        trigger: 'blur',
                                    }}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"step":0.01,"placeholder":"Укажите объем финансирования","size":"large"},model:{value:(source.amount),callback:function ($$v) {_vm.$set(source, "amount", $$v)},expression:"source.amount"}})],1)],1),_c('a-form-model-item',{attrs:{"prop":'funding_sources.' + index + '.comment',"label":"Комментарий","rules":{
                                    required: false,
                                    message: _vm.$t('wgr.field_require'),
                                    trigger: 'blur',
                                }}},[_c('a-textarea',{staticClass:"equipment_textarea",attrs:{"disabled":_vm.viewMode,"placeholder":"Добавьте короткий комментарий","auto-size":{ minRows: 6, maxRows: 6 }},model:{value:(source.comment),callback:function ($$v) {_vm.$set(source, "comment", $$v)},expression:"source.comment"}})],1)],1)])}),_c('a-button',{staticClass:"mt-1 mb-5",attrs:{"disabled":_vm.viewMode,"type":"default","block":"","size":"large"},on:{"click":function($event){return _vm.addSource()}}},[_vm._v(" Добавить источник финансирования ")])],2):_vm._e()]),_c('div',{staticClass:"form_block"},[_c('div',{staticClass:"form_block__header"},[_c('h3',[_vm._v("Рабочие места")])]),_c('div',{staticClass:"grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2"},[(_vm.actionInfo?.jobs_temporary || _vm.viewMode)?_c('a-form-model-item',{ref:"jobs_temporary",attrs:{"label":"Временные","rules":{
                            required: false,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"jobs_temporary"}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"placeholder":"Укажите количество временных рабочих мест","size":"large"},model:{value:(_vm.form.jobs_temporary),callback:function ($$v) {_vm.$set(_vm.form, "jobs_temporary", $$v)},expression:"form.jobs_temporary"}})],1):_vm._e(),(_vm.actionInfo?.jobs_permanent || _vm.viewMode)?_c('a-form-model-item',{ref:"jobs_permanent",attrs:{"label":"Постоянные","rules":{
                            required: false,
                            message: _vm.$t('wgr.field_require'),
                            trigger: 'blur',
                        },"prop":"jobs_permanent"}},[_c('a-input-number',{staticClass:"w-full",attrs:{"disabled":_vm.viewMode,"placeholder":"Укажите количество постоянных рабочих мест","size":"large"},model:{value:(_vm.form.jobs_permanent),callback:function ($$v) {_vm.$set(_vm.form, "jobs_permanent", $$v)},expression:"form.jobs_permanent"}})],1):_vm._e()],1)]),_c('div',{staticClass:"form_block"},[_c('div',{staticClass:"form_block__action_buttons"},[_c('a-button-group',[(_vm.actions?.change_status?.availability && _vm.currentStatus)?_c('a-button',{staticClass:"md:px-6 lg:px-6",attrs:{"type":"primary","size":"large","loading":_vm.loading},on:{"click":function($event){return _vm.changeStatus(_vm.currentStatus)}}},[_vm._v(" "+_vm._s(_vm.currentStatus?.btn_title ? _vm.currentStatus.btn_title : _vm.currentStatus.name)+" ")]):_vm._e(),_c('a-dropdown',{attrs:{"type":"primary","size":"large","placement":"topRight"}},[(('change_status' in _vm.actions) && _vm.actions?.change_status?.availability)?_c('a-button',{attrs:{"type":"primary","size":"large","loading":_vm.loading,"flaticon":"","icon":"fi-rr-menu-dots-vertical"}}):_vm._e(),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.statusList),function(status){return _c('a-menu-item',{key:status.code,staticClass:"flex items-center",on:{"click":function($event){return _vm.changeStatus(status)}}},[_c('a-badge',{attrs:{"color":status.color}}),_vm._v(" "+_vm._s(status.btn_title ? status.btn_title : status.name)+" ")],1)}),1)],1)],1),(_vm.actionInfo)?_c('a-button',{attrs:{"type":"default","loading":_vm.loading,"block":"","size":"large"},on:{"click":function($event){return _vm.formSubmit()}}},[_vm._v(" Сохранить изменения ")]):_vm._e()],1)])]):_c('a-skeleton',{attrs:{"active":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }